import FattyLiver from "../assets/Fatty Liver Score.png";
import PhysicalScore from "../assets/Physical Activity Score.png";
import MetabolicScore from "../assets/Metabolic Score.png";
import SleepSore from "../assets/Sleep Score.png";
import StressSore from "../assets/Stress Score.png";

export const getScoreImage = (url) => {

  switch (url) {
    case "Fatty Liver Score":
      return FattyLiver;
    case "Physical Activity Score":
      return PhysicalScore;
    case "Metabolic Score":
      return MetabolicScore;
    case "Sleep Score":
      return SleepSore;
    case "Stress Score":
      return StressSore;
  }
};
