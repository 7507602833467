import React, { useState } from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  diseaseAbout: {
    borderRadius: "5px",
    borderWidth: "1.5px",
    borderColor: "#E3F0F0",
    flexDirection: "column",
    marginRight: "5px",
    padding: "10px",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "60%",
  },
  aboutTxt: {
    fontSize: "11px",
    fontWeight: "medium",
    fontFamily: "NotoSans",
  },
  aboutArea: {
    paddingTop: "10px",
    paddingHorizontal: "5px",
  },
  listItem: {
    flexDirection: "row",
    paddingTop: 5,
    alignItems: "flex-start",
  },
  bulletPoint: {
    width: 5,
    fontSize: "11px",
    marginRight: 2,
  },
  aboutPts: {
    fontSize: "11px",
    fontFamily: "NotoSans",
    lineHeight: "1.5px",
  },
});

function About({ about }) {
  return (
    <View style={styles.diseaseAbout}>
      <Text style={styles.aboutTxt}>What does the risk indicate?</Text>
      <View style={styles.aboutArea}>
        <Text style={styles.aboutPts}>{about}</Text>
      </View>
    </View>
  );
}

export default About;
