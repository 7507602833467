import { createTheme } from '@mui/material/styles';

import {PaletteColorOptions} from "@mui/material";

import paletteColors from './palette';
import typography from './typography';

import MuiAppBar from './components/MuiAppBar';
import MuiButton from './components/MuiButton';
import MuiCard from './components/MuiCard';
import MuiDialog from './components/MuiDialog';
import MuiDialogTitle from './components/MuiDialogTitle';
import MuiDialogContent from './components/MuiDialogContent';
import MuiDialogActions from './components/MuiDialogActions';
import MuiDivider from './components/MuiDivider';
import MuiTypography from './components/MuiTypography';
import MuiAccordion from './components/MuiAccordion';
import MuiAccordionSummary from './components/MuiAccordionSummary';
import MuiAccordionDetails from './components/MuiAccordionDetails';
import MuiTab from './components/MuiTab';

declare module "@mui/material/styles" {
    interface TypographyVariants {
        displayLarge: React.CSSProperties;
        displayMedium: React.CSSProperties;
        displaySmall: React.CSSProperties;
        headingLarge: React.CSSProperties;
        headingMedium: React.CSSProperties;
        headingSmall: React.CSSProperties;
        titleLarge: React.CSSProperties;
        titleMedium: React.CSSProperties;
        titleSmall: React.CSSProperties;
        bodyLarge: React.CSSProperties;
        bodyMedium: React.CSSProperties;
        bodySmall: React.CSSProperties;
        labelLarge: React.CSSProperties;
        labelMedium: React.CSSProperties;
        labelSmall: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        displayLarge: React.CSSProperties;
        displayMedium: React.CSSProperties;
        displaySmall: React.CSSProperties;
        headingLarge: React.CSSProperties;
        headingMedium: React.CSSProperties;
        headingSmall: React.CSSProperties;
        titleLarge: React.CSSProperties;
        titleMedium: React.CSSProperties;
        titleSmall: React.CSSProperties;
        bodyLarge: React.CSSProperties;
        bodyMedium: React.CSSProperties;
        bodySmall: React.CSSProperties;
        labelLarge: React.CSSProperties;
        labelMedium: React.CSSProperties;
        labelSmall: React.CSSProperties;
    }

    interface Palette {
        tertiary: PaletteColorOptions;
        onPrimary: PaletteColorOptions;
        onSecondary: PaletteColorOptions;
        onTertiary: PaletteColorOptions;
        onError: PaletteColorOptions;
        primaryContainer: PaletteColorOptions;
        secondaryContainer: PaletteColorOptions;
        tertiaryContainer: PaletteColorOptions;
        errorContainer: PaletteColorOptions;
        onPrimaryContainer: PaletteColorOptions;
        onSecondaryContainer: PaletteColorOptions;
        onTertiaryContainer: PaletteColorOptions;
        onErrorContainer: PaletteColorOptions;
        surface: PaletteColorOptions;
        onSurface: PaletteColorOptions;
        surfaceVariant: PaletteColorOptions;
        onSurfaceVariant: PaletteColorOptions;
        outline: PaletteColorOptions;
        outlineVariant: PaletteColorOptions;
        onBackground: PaletteColorOptions;
        primaryFixed: PaletteColorOptions;
        onPrimaryFixed: PaletteColorOptions;

        low: PaletteColorOptions;
        onLow: PaletteColorOptions;
        lowContainer: PaletteColorOptions;
        onLowContainer: PaletteColorOptions;
        moderate: PaletteColorOptions;
        onModerate: PaletteColorOptions;
        moderateContainer: PaletteColorOptions;
        onModerateContainer: PaletteColorOptions;
        high: PaletteColorOptions;
        onHigh: PaletteColorOptions;
        highContainer: PaletteColorOptions;
        onLighContainer: PaletteColorOptions;
        
    }

    interface PaletteOptions {
        tertiary: PaletteColorOptions;
        onPrimary: PaletteColorOptions;
        onSecondary: PaletteColorOptions;
        onTertiary: PaletteColorOptions;
        onError: PaletteColorOptions;
        primaryContainer: PaletteColorOptions;
        secondaryContainer: PaletteColorOptions;
        tertiaryContainer: PaletteColorOptions;
        errorContainer: PaletteColorOptions;
        onPrimaryContainer: PaletteColorOptions;
        onSecondaryContainer: PaletteColorOptions;
        onTertiaryContainer: PaletteColorOptions;
        onErrorContainer: PaletteColorOptions;
        surface: PaletteColorOptions;
        onSurface: PaletteColorOptions;
        surfaceVariant: PaletteColorOptions;
        onSurfaceVariant: PaletteColorOptions;
        outline: PaletteColorOptions;
        outlineVariant: PaletteColorOptions;
        onBackground: PaletteColorOptions;
        primaryFixed: PaletteColorOptions;
        onPrimaryFixed: PaletteColorOptions;

        low: PaletteColorOptions;
        onLow: PaletteColorOptions;
        lowContainer: PaletteColorOptions;
        onLowContainer: PaletteColorOptions;
        moderate: PaletteColorOptions;
        onModerate: PaletteColorOptions;
        moderateContainer: PaletteColorOptions;
        onModerateContainer: PaletteColorOptions;
        high: PaletteColorOptions;
        onHigh: PaletteColorOptions;
        highContainer: PaletteColorOptions;
        onLighContainer: PaletteColorOptions;
    }
};

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
      displayLarge: true;
      displayMedium: true;
      displaySmall: true;
      headingLarge: true;
      headingMedium: true;
      headingSmall: true;
      titleLarge: true;
      titleMedium: true;
      titleSmall: true;
      bodyLarge: true;
      bodyMedium: true;
      bodySmall: true;
      labelLarge: true;
      labelMedium: true;
      labelSmall: true;
    }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        tertiary: true;
        onPrimary: true;
        onSecondary: true;
        onTertiary: true;
        onError: true;
        primaryContainer: true;
        secondaryContainer: true;
        tertiaryContainer: true;
        errorContainer: true;
        onPrimaryContainer: true;
        onSecondaryContainer: true;
        onTertiaryContainer: true;
        onErrorContainer: true;
        surface: true;
        onSurface: true;
        surfaceVariant: true;
        onSurfaceVariant: true;
        outline: true;
        outlineVariant: true;
        onBackground: true;
        primaryFixed: true;
        onPrimaryFixed: true;

        low: true;
        onLow: true;
        lowContainer: true;
        onLowContainer: true;
        moderate: true;
        onModerate: true;
        moderateContainer: true;
        onModerateContainer: true;
        high: true;
        onHigh: true;
        highContainer: true;
        onLighContainer: true;
    }
}

// Update the Chip's color prop options
declare module "@mui/material/Chip" {
    interface ChipPropsColorOverrides {
        tertiary: true;
        onPrimary: true;
        onSecondary: true;
        onTertiary: true;
        onError: true;
        primaryContainer: true;
        secondaryContainer: true;
        tertiaryContainer: true;
        errorContainer: true;
        onPrimaryContainer: true;
        onSecondaryContainer: true;
        onTertiaryContainer: true;
        onErrorContainer: true;
        surface: true;
        onSurface: true;
        surfaceVariant: true;
        onSurfaceVariant: true;
        outline: true;
        outlineVariant: true;
        onBackground: true;
        primaryFixed: true;
        onPrimaryFixed: true;

        low: true;
        onLow: true;
        lowContainer: true;
        onLowContainer: true;
        moderate: true;
        onModerate: true;
        moderateContainer: true;
        onModerateContainer: true;
        high: true;
        onHigh: true;
        highContainer: true;
        onLighContainer: true;
    }
}

const theme = createTheme({
    palette: {
        ...paletteColors,
        mode: 'light'
    },
    typography,
    components: {
        MuiAppBar,
        MuiTypography,
        MuiButton,
        MuiCard,
        MuiDivider,
        MuiDialog,
        MuiDialogTitle,
        MuiDialogContent,
        MuiDialogActions,
        MuiAccordion,
        MuiAccordionSummary,
        MuiAccordionDetails,
        MuiTab
    }
});

export default theme;