const typography = {
    fontFamily: 'Noto Sans',
    displayLarge: {
        fontSize: '3.563rem', //57px
        fontWeight: 400,
        lineHeight: '4rem',
        letterSpacing: '-0.25px'
    },
    displayMedium: {
        fontSize: '2.813rem', //45px
        fontWeight: 400,
        lineHeight: '3.25rem',
    },
    displaySmall: {
        fontSize: '2.25rem', //36px
        fontWeight: 400,
        lineHeight: '2.75rem',
    },
    headingLarge: {
        fontSize: '2rem', //32px
        fontWeight: 400,
        lineHeight: '2.5rem',
    },
    headingMedium: {
        fontSize: '1.75rem', //28px
        fontWeight: 400,
        lineHeight: '2.25rem',
    },
    headingSmall: {
        fontSize: '1.5rem', //24px
        fontWeight: 400,
        lineHeight: '2rem',
    },
    titleLarge: {
        fontSize: '1.375rem', //22px
        fontWeight: 400,
        lineHeight: '1.75rem',
    },
    titleMedium: {
        fontSize: '1.125rem', //18px
        fontWeight: 500,
        lineHeight: '1.5rem',
        letterSpacing: '0.15px',
    },
    titleRegular: {
        fontSize: '1rem', //16px
        fontWeight: 400,
        lineHeight: '1.75rem',
        letterSpacing: '0.15px',
    },
    titleSmall: {
        fontSize: '0.875rem', //14px
        fontWeight: 500,
        lineHeight: '1.25rem',
        letterSpacing: '0.1px',
    },
    bodyLarge: {
        fontSize: '1rem', //16px
        fontWeight: 400,
        lineHeight: '1.5rem',
        letterSpacing: '0.5px',
    },
    bodyMedium: {
        fontSize: '0.875rem', //14px
        fontWeight: 400,
        lineHeight: '1.25rem',
        letterSpacing: '0.25px',
    },
    bodyRegular: {
        fontSize: '0.75rem', //12px
        fontWeight: 400,
        lineHeight: '1rem',
        letterSpacing: '0.15px',
    },
    bodySmall: {
        fontSize: '0.688rem', //11px
        fontWeight: 400,
        lineHeight: '1rem',
        letterSpacing: '0.4px',
    },
    labelLarge: {
        fontSize: '1rem', //16px
        fontWeight: 400,
        lineHeight: '1.5rem',
        letterSpacing: '0.1px',
    },
    labelMedium: {
        fontSize: '0.875rem', //14px
        fontWeight: 400,
        lineHeight: '1.25rem',
        letterSpacing: '0.5px',
    },
    labelRegular: {
        fontSize: '0.75rem', //12px
        fontWeight: 400,
        lineHeight: '1rem',
        letterSpacing: '0.15px',
    },
    labelSmall: {
        fontSize: '0.688rem', //11px
        fontWeight: 500,
        lineHeight: '1rem',
        letterSpacing: '0.5px',
    },
    button: {
    }
};

export default typography;