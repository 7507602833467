import { AxiosResponse } from 'axios'
import { makeAPIRequest } from '../hook/request';
import { API_ENDPOINT } from '../utils/constants';

class DiseaseAPI {
  async getAllDisease(userId: string): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'GET',
      url: `${API_ENDPOINT.GET_ALL_DISEASE_PREDICTIONS}?user_id=${userId}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async getAllScores(userId: string): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'GET',
      url: `${API_ENDPOINT.GET_ALL_SCORES_PREDICTIONS}?user_id=${userId}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async saveScore(params: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      url: `${API_ENDPOINT.SAVE_SCORE}`,
      data: params,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async checkPercentageScore(userId: string, ): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'GET',
      url: `${API_ENDPOINT.GET_ANSWER_COUNTS_PERCENTAGE}?user_id=${userId}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async getScoreLabels(userId: string, scoreName: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'GET',
      url: `${API_ENDPOINT.GET_SCORE_LABELS}?user_id=${userId}&score_name=${scoreName}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  
  async saveInitialPageUserDetails(params: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      url: `${API_ENDPOINT.SAVE_USER_DETAILS}`,
      data: params,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  
  async getUserDetails(userId: string, client: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'GET',
      url: `${API_ENDPOINT.GET_USER_DETAILS}?client=${client}&user_id=${userId}`,
      // client=madmachines&user_id=user_id_test
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async savePdf(params: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      url: `${API_ENDPOINT.SAVE_REPORTS}`,
      data: params,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

}

export default new DiseaseAPI()
