import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  diseaseAbout: {
    borderRadius: "5px",
    borderWidth: "1.5px",
    borderColor: "#E3F0F0",
    flexDirection: "column",
    padding: "10px",
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: "40%",
  },
  riskFactorArea: {
    paddingTop: "10px",
    width: "90%",
    paddingHorizontal: "7px",
  },
  listItem: {
    flexDirection: "row",
    paddingTop: 5,
    alignItems: "flex-start",
  },
  bulletPoint: {
    width: 5,
    fontSize: 10,
    marginRight: 2,
  },

  riskFactorPts: {
    fontSize: "11px",
    fontFamily: "NotoSans",
    lineHeight: "1.5px",
  },
  riskFactorTxt: {
    fontSize: "11px",
    fontWeight: "medium",
    fontFamily: "NotoSans",
  },
});

function RiskFacotrs({ riskFactor }) {
  return (
    <View style={styles.diseaseAbout}>
      <Text style={styles.riskFactorTxt}>Risk Factors</Text>
      <View style={styles.riskFactorArea}>
        {riskFactor.map((e) => {
          return (
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.riskFactorPts}>{e}</Text>
            </View>
          );
        })}
      </View>
    </View>
  );
}

export default RiskFacotrs;
