import React from "react";
import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import AppLayout from "./app-layout";
import RiskTab from "./dashboard/risk-tabs/RiskTab";
// import Dashboard from './Dashboard';

const Dashboard = lazy(() => import("./dashboard"));
const HealthScoreResult = lazy(() => import("./healthScoreResult/summary"));

const routes = [
  {
    element: <AppLayout />,
    children: [
      { path: "/:email", element: <Dashboard pageTitle="Home" /> },
      { path: "/dashboard/healthRisk/:email", element: <RiskTab /> },
      {
        path: "/dashboard/dialog/:openDialog",
        element: <Dashboard pageTitle="Health Prognosis Report" />,
      },
    ],
  },
  {
    path: "/score/:tabName/:email/:scoreName",
    element: <HealthScoreResult />,
  },
];

export const initRouting = () => {
  const router = createBrowserRouter(routes);
  return router;
};
