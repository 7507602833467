import paletteColors from '../palette';

const MuiTab = {
    styleOverrides: {
        root: {
            '&.Mui-selected': {
                backgroundColor: `${paletteColors.surfaceVariant.main}`,
            },
            '&.Mui-selected .dot':{
                display: 'flex',
                alignItems: 'center'
            },
            '&.Mui-selected .dot::before': {
                content:'"• "',
                fontSize: '24px',
                display: "inline-block",
                marginRight: '8px'
            }
        },
    }
};

export default MuiTab;