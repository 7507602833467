import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import FooterImage from "../../../assets/Footer.svg";
import { DEFAULT_SUMMARY_LIST_DISPLAY_COUNT } from "../../../utils/constants";
import CustomAbout from "../CustomAbout";
import CustomRecommendation from "../CustomRecommendation";
import { DiseaseDetail } from "../../../utils/interfaces";

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <div style={{ textAlign: "center" }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

const CustomAccordian = ({
  isDisplayAllSummary = false,
  dieseaseDetails = [],
}: {
  isDisplayAllSummary?: boolean;
  dieseaseDetails: DiseaseDetail[];
}) => {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [tabValue, setTabValue] = useState(0);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      setTabValue(0);
    };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderTab = (tabValueIndex: number) => {
    const selectedRisk =
      dieseaseDetails.find(
        (item: DiseaseDetail) => item.risk_name === expanded
      ) || null;
    switch (tabValueIndex) {
      case 0:
        return (
          <CustomAbout
            indicateList={
              selectedRisk && selectedRisk.about
                ? selectedRisk.about.filter((e: string) => e !== "")
                : []
            }
            factorList={
              selectedRisk && selectedRisk["Risk Factors"]
                ? selectedRisk["Risk Factors"]
                : []
            }
          />
        );
      case 1:
        return (
          <CustomRecommendation
            recommendationList={
              selectedRisk && selectedRisk.recommendations
                ? selectedRisk.recommendations[0]
                : []
            }
          />
        );
      default:
        return null;
    }
  };

  const RenderList = isDisplayAllSummary
    ? dieseaseDetails
    : dieseaseDetails.slice(0, DEFAULT_SUMMARY_LIST_DISPLAY_COUNT);

  return (
    <>
      {RenderList &&
        RenderList.length > 0 &&
        RenderList.map((item: DiseaseDetail, itemIndex: number) => (
          <Accordion
            key={`accordion-index-${itemIndex}`}
            expanded={expanded === item.risk_name}
            onChange={handleChange(item.risk_name)}
            sx={{
              marginBottom: 2,
              boxShadow: 1,
              border: "1px solid #e3f0f0",
              borderRadius: 1,
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={
                <ArrowForwardIosSharpIcon
                  sx={{
                    fontSize: "0.9rem",
                    color: "primary.main",
                    transition: "transform 0.3s",
                    transform: expanded === item.risk_name ? "rotate(-180deg)" : "rotate(90deg)",
                  }}
                />
              }
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: expanded === item.risk_name ? "surface.main" : "#fff",
              }}
            >
              <img
                src={item.image_url ? item.image_url : FooterImage}
                alt="accordion-image"
                style={{ height: 50, width: 50, marginRight: 16 }}
              />
              <Typography variant="titleSmall">{item.risk_name}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ padding: 2, marginBottom: 5 }}
            >
              {expanded === item.risk_name && (
                <>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      bgcolor: "surface.main",
                    }}
                  >
                    <Tabs
                      value={tabValue}
                      onChange={handleChangeTab}
                      aria-label="basic tabs example"
                      TabIndicatorProps={{
                        sx: { backgroundColor: "#80d4da" }, // Customizing the indicator color
                      }}
                      variant="fullWidth"
                      centered
                    >
                      <Tab
                        label="About"
                        {...a11yProps(0)}
                        sx={{ fontSize: "0.875rem", fontWeight: 500 }}
                      />
                      <Tab
                        label="Recommendation"
                        {...a11yProps(1)}
                        sx={{ fontSize: "0.875rem", fontWeight: 500 }}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={tabValue} index={tabValue}>
                    {renderTab(tabValue)}
                  </TabPanel>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};

export default CustomAccordian;
