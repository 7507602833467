import React from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import HealthiansLogo from "../assets/healthians.png";
import Logo from "../assets/logo.png";


const styles = StyleSheet.create({
    header1: {
        flexDirection: 'row',
        paddingHorizontal: 15,
        paddingVertical: 20,
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#00A0A8'
    },
    header1Content: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop:'10px'
    },
    boldText: {
        color: "white",
        fontWeight: 'bold',
        fontSize: '7px',
        fontFamily:'NotoSans'
    },
    normalText: {
        color: "white",
        fontSize: '7px',
        fontFamily:'NotoSans'
    },
    logoCircle: {
        height: '35px',
        width: '35px',
        borderRadius: '100%',
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        height: '30px',
        width: '110px',
    },
    logo: {
        height: '10px',
        width: '29px',
    },
    header2: {
        flexDirection: 'row'
    },
    line: {
        width: '1px',
        height:'15px',
        marginRight:'10px',
        backgroundColor:'white'
    },
    content: {
        marginRight: '15px'
    }
});

function Header1() {
    return <View style={styles.header1}>
        <Image src={HealthiansLogo} style={styles.image} />
        <View style={styles.header1Content}>
            <View style={styles.content}>
                <Text style={styles.boldText} >22+</Text>
                <Text style={styles.normalText}>State-of-the-art labs</Text>
            </View>
            <View style={styles.line} />

            <View style={styles.content}>
                <Text style={styles.boldText} >100 Million</Text>
                <Text style={styles.normalText}>Tests Reports</Text>
            </View>
            <View style={styles.line} />

            <View style={styles.content}>
                <Text style={styles.boldText} >2000+</Text>
                <Text style={styles.normalText}>Technicians & Pathologists</Text>
            </View>
            <View style={styles.logoCircle}>
                <Image src={Logo} style={styles.logo} />
            </View>
        </View>
    </View>
}

export default Header1