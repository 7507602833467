import React, { useState } from "react";
import { Link, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import ReportPrint from "../../ReportPrint/ReportPrint";

const styles = StyleSheet.create({
  body: { paddingTop: 140, paddingBottom: 98, fontFamily: "NotoSans" },
  header: {
    textAlign: "center",
    fontSize: 20,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 12,
  },
  section: {
    marginBottom: 10,
    borderRadius: "2px",
    borderColor: "red",
    borderWidth: "4px",
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  listItem: {
    marginBottom: 2,
  },
});

function DiseaseReports({ diseaseReports, userName, id,error1 = "" }) {
  return (
    <Page style={styles.body}>
      <View wrap={false} style={styles.header} fixed>
        <Header userName={userName} />
      </View>

      {Object.keys(diseaseReports.risks).map((riskLevel, riskLevelIndex) =>
        diseaseReports.risks[riskLevel].map((disease, diseaseIndex) => (
          <ReportPrint
            key={diseaseIndex}
            name={disease.risk_name}
            about={disease.about}
            riskFactor={disease["Risk Factors"]}
            recommendations={disease.recommendations[0]}
            image={disease.image_url}
            error={error1}
            ishealthScore={false}
          />
        ))
      )}

      <View wrap={false} style={styles.footer} fixed>
        <Footer height={80} id={id} />
      </View>
    </Page>
  );
}

export default DiseaseReports;
