import {Suspense} from 'react';
import './App.css';

import {RouterProvider} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import theme from './utils/Theme/theme';
import { ProvideApp } from "./hook/check-app";
import { AxiosInterceptor } from "./hook/request";
import {initRouting} from './components/AppRoutes';

function App() {

  const router = initRouting()

  return (
    <ThemeProvider theme={theme}>
      <ProvideApp>
        <AxiosInterceptor>
          <Suspense>
            <RouterProvider router={router}/>
          </Suspense>
        </AxiosInterceptor>
      </ProvideApp>
    </ThemeProvider>
  );
}

export default App;
