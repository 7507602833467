import paletteColors from '../palette';

const MuiAppBar = {
    styleOverrides: {
        root: {
            boxShadow: 'none',
        },
        colorDefault: {
            backgroundColor: paletteColors.surfaceVariant.main,
            backgroundImage: 'none',
            padding: '1rem',
        },
        colorPrimary: {
            backgroundColor: paletteColors.primary.main,
            color: paletteColors.onPrimary.main,
            backgroundImage: 'none',
            padding: '1rem',
        }
    }
};

export default MuiAppBar;