import { Alert, Box, Button, Snackbar, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { useApp } from "../../../hook/check-app";
import CustomDisease from "../../helpers/CustomDiseaseList";
import Slide, { SlideProps } from "@mui/material/Slide";
import Entry from "../../../pdf/screens/entry/Entry";

const tabList = ["Priority Action", "Timely Action", "Routine Action"];

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

type TransitionProps = Omit<SlideProps, "direction">;
function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

function RiskTab() {
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const { email } = useParams();

  const navigate = useNavigate();

  const {
    disease,
    setEmail,
    showSnackbar,
    snackbarSeverity,
    snackbarMessage,
    onHideSnackbar,
    canDownloadPdf,
  } = useApp();
  const { tabName } = location.state || {};

  useEffect(() => {
    if (email) setEmail(email);
  }, [email]);

  useEffect(() => {
    if (tabName) {
      const findTabIndex = tabList.findIndex((item) => item === tabName);

      if (findTabIndex > -1) {
        setTabValue(findTabIndex);
      }
    }
  }, [tabName]);

  const renderTab = (tabValueIndex: number) => {
    switch (tabValueIndex) {
      case 0:
        return (
          <CustomDisease
            risk="high-risk"
            dieseaseDetails={(disease && disease["High Risk"]) || []}
          />
        );
      case 1:
        return (
          <CustomDisease
            risk="moderate-risk"
            dieseaseDetails={(disease && disease["Moderate Risk"]) || []}
          />
        );
      case 2:
        return (
          <CustomDisease
            risk="low-risk"
            dieseaseDetails={(disease && disease["Low Risk"]) || []}
          />
        );
      default:
        break;
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "surface.main",
          maxHeight: "120px",
        }}
      >
        <Tabs value={tabValue} onChange={handleChangeTab}>
          <Tab
            label="Priority Action"
            {...a11yProps(0)}
            className={styles.tabItem}
          />
          <Tab
            label="Timely Action"
            {...a11yProps(1)}
            className={styles.tabItem}
          />
          <Tab
            label="Routine Action"
            {...a11yProps(2)}
            className={styles.tabItem}
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(76vh - 150px)", // Adjust as needed
          position: "relative",
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            padding: 2,
            maxHeight: "calc(90% - 60px)", // Adjust based on button height and bottom margin
          }}
        >
          {renderTab(tabValue)}
        </Box>
        <Button
          sx={{
            position: "absolute",
            bottom: 20,
            left: "50%",
            transform: "translateX(-50%)",
            width: "40%",
            bgcolor: "white", // Adjust color as needed
            color: "primary",
            zIndex: 1, // Ensure the button is above other content
            border: "2px solid #e3f0f0 ",
            "&:hover": {
              boxShadow: "none", // Ensure no shadow on hover
            },
            "&:focus": {
              boxShadow: "none", // Ensure no shadow on focus
            },
          }}
          onClick={() => {
            navigate(`/${email}`);
          }}
        >
          Home
        </Button>
      </Box>
      {showSnackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          TransitionComponent={TransitionDown}
          open={true}
          autoHideDuration={6000}
          onClose={onHideSnackbar}
        >
          <Alert
            onClose={onHideSnackbar}
            severity={snackbarSeverity}
            icon={false}
            variant="filled"
            sx={{
              width: "100%",
              backgroundColor:
                snackbarSeverity === "success" ? "#C8F2F5" : "#E86666",
              color: snackbarSeverity === "success" ? "black" : "white",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
      {canDownloadPdf && <Entry />}
    </>
  );
}

export default RiskTab;
