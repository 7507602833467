import {AxiosResponse} from 'axios'
import {makeAPIRequest} from '../hook/request';
import { API_ENDPOINT } from '../utils/constants';

class DiseaseAPI {
  async getUserDetails(userId: string): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'GET',
      url: `${API_ENDPOINT.GET_USER_DETAILS}?email=${userId}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

}

export default new DiseaseAPI()
