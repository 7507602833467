import React from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import About from "../Common/About";
import RiskFacotrs from "../Common/RiskFactors";
import Recommendation from "../Common/Recommendation";
import Anemia from "../assets/Anemia.png";
import PoorLogo from "../assets/Poor.png";
import GoodLogo from "../assets/Good.png";
import MediumLogo from "../assets/Medium.png";
import { getScoreImage } from "../utils/helpers";

const styles = StyleSheet.create({
  diseaseReport: {
    flexDirection: "column",
  },
  diseaseName: {
    backgroundColor: "#f4fafb",
    width: "100%",
    flexDirection: "row",
    paddingHorizontal: "16px",
    paddingVertical: "8px",
    alignItems: "center",
    marginVertical: "6px",
    justifyContent: "space-between",
  },
  diseaseIcon: {
    height: "20px",
    width: "22px",
    marginRight: "10px",
  },
  diseaseNameTxt: {
    fontSize: "13px",
    fontFamily: "NotoSans",
    fontWeight: "medium",
    marginLeft: 10,
  },
  diseaseReportSection: {
    paddingHorizontal: "15px",
  },
  recommend: {
    flexDirection: "column",
  },
  diseaseReportSection1: {
    flexDirection: "row",
    marginBottom: "10px",
    display: "flex",
  },
  diseaseAbout: {
    borderRadius: "2px",
    borderColor: "red",
    borderWidth: "4px",
  },

  healthScoreStatusIcon: {
    height: "14px",
    width: "14px",
    marginRight: "5px",
  },
  goodScore: {
    color: "#34a853",
    fontSize: "10px",
    fontFamily: "NotoSans",
  },
  poorScore: {
    color: "#ea4335",
    fontSize: "10px",
    fontFamily: "NotoSans",
  },
  mediumScore: {
    color: "#fbbc04",
    fontSize: "10px",
    fontFamily: "NotoSans",
  },
});

function ReportPrint({
  key,
  name,
  about,
  riskFactor,
  recommendations,
  score,
  maxScore,
  error,
  image,
  label,
  scoreLabel,
  ishealthScore = false,
}) {

  const getScoreColor = (value) => {
    if (value === "Low") {
      return styles.goodScore;
    } else if (value === "Medium") {
      return styles.mediumScore;
    } else if (value === "High") {
      return styles.poorScore;
    }
  };
  const getIcon = (value) => {
    if (value === "Low") {
      return GoodLogo;
    } else if (value === "Medium") {
      return MediumLogo;
    } else if (value === "High") {
      return PoorLogo;
    }
  };
  return (
    <View style={styles.diseaseReport} key={key}>
      <View style={styles.diseaseName}>
        <View style={{ flexDirection: "row" }}>
          {image && image.length > 0 ? (
            <Image src={image} style={styles.diseaseIcon} />
          ) : (
            <Image
              cache={false}
              src={
                ishealthScore
                  ? getScoreImage(name)
                  : `https://d3ggzbwb0yiai7.cloudfront.net/madmachines/Health_Score/${name}.png`
              }
              style={styles.diseaseIcon}
            />
          )}
          <Text style={styles.diseaseNameTxt}>{name}</Text>
        </View>
        {score && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ flexDirection: "row", marginRight: "5px" }}>
              <Image
                src={getIcon(scoreLabel)}
                style={styles.healthScoreStatusIcon}
              />
              <Text style={getScoreColor(scoreLabel)}>{label}</Text>
            </View>
            {score && maxScore && (
              <View
                style={{
                  borderRadius: "8px",
                  borderWidth: "1px",
                  borderColor: "#E3F0F0",
                  padding: "2px 4px",
                }}
              >
                <Text style={getScoreColor(scoreLabel)}>{score}/100</Text>
              </View>
            )}
          </View>
        )}
      </View>
      <View style={styles.diseaseReportSection}>
        <View style={styles.diseaseReportSection1}>
          <About about={about} />
          <RiskFacotrs riskFactor={riskFactor} />
        </View>
        <View style={styles.recommend}>
          <Recommendation recommendations={recommendations} />
        </View>
      </View>
    </View>
  );
}

export default ReportPrint;
