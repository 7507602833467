import { useState } from "react";
import styles from "./styles.module.css";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import MobileStepper from "@mui/material/MobileStepper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import Backcon from "@mui/icons-material/NavigateBeforeRounded";
import NextIcon from "@mui/icons-material/NavigateNextRounded";

const CustomAbout = ({ indicateList, factorList }: any) => {
  // const indicateList = [
  //     "High risk indicates that you have a very high likelihood of developing iron deficiency anemia (IDA).",
  //     "IDA is a preventable condition which occurs due to imbalance between iron intake, body stores and loss of iron, leading to insufficient red blood cells production.",
  //     "Managing the risk of IDA is crucial, as it can contribute to an elevated risk of cardiovascular diseases, diabetes, immune system impairment, and poor pregnancy outcomes."
  // ];
  // const factorList = [
  //     "Surgery", "Infection", "Blood Loss", "Pregnancy", "Medications", "Malnutrition", "Inflammation", "Genetic Factors",
  //     "Chronic Diseases", "Gastrointestinal Issues", "Heavy Menstrual Bleeding", "Inadequate Dietary Iron Intake"
  // ];

  const [activeIndicate, setActiveIndicate] = useState(0);

  const handleNext = () => {
    setActiveIndicate((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveIndicate((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box
      style={{
        backgroundColor: "#f8feff",
      }}
    >
      <Typography
        component="div"
        variant="labelLarge"
        className={styles.aboutTitle}
      >
        {/* <IndicateIcon /> */}
        What does the risk indicate?
      </Typography>
      <Box
        sx={{
          border: 1,
          borderColor: "outlineVariant.main",
          borderRadius: "4px",
          padding: "16px 0px",
          display: "flex",
          alignItems: "flex-start",
        }}
        className={styles.indicateContainer}
      >
        <IconButton
          className={styles.backIcon}
          onClick={() => {
            if (activeIndicate > 0) {
              handleBack();
            }
          }}
        >
          {activeIndicate > 0 && <Backcon sx={{ color: "primary.main" }} />}
        </IconButton>
        <Typography variant="bodyMedium" className={styles.indicateTypo}>
          {indicateList[activeIndicate]}
        </Typography>
        <IconButton
          className={styles.nextIcon}
          onClick={() => {
            if (activeIndicate < indicateList.length - 1) {
              handleNext();
            }
          }}
        >
          {activeIndicate < indicateList.length - 1 && (
            <NextIcon sx={{ color: "primary.main" }} />
          )}
        </IconButton>
      </Box>

      <MobileStepper
        variant="dots"
        steps={indicateList.length}
        position="static"
        color="primary"
        activeStep={activeIndicate}
        sx={{
          backgroundColor: "#f8feff",
          justifyContent: "space-around",
          mt: 2,
          mb: 2,
          "& .MuiMobileStepper-dotActive": { bgcolor: "primary.main" },
        }}
        nextButton={<></>}
        backButton={<></>}
      />

      <Divider />

      <Typography
        component="div"
        variant="labelLarge"
        className={styles.aboutFactor}
      >
        {/* <RiskFactorIcon /> */}
        Risk Factors
      </Typography>

      <Box className={styles.factorContainer}>
        {factorList.map((factor: any, factorIndex: number) => (
          <Chip
            variant="outlined"
            key={`factor-${factorIndex}`}
            label={factor}
            style={{ borderWidth: "1px", borderColor: "#E3F0F0" }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CustomAbout;
