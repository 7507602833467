import paletteColors from '../palette';

const MuiDivider = {
    styleOverrides: {
        root: {
            backgroundColor: paletteColors.outlineVariant.main
        },
    }
};

export default MuiDivider;