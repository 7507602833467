import styles from './styles.module.css';
import Box from "@mui/material/Box";

import { ReactComponent as AllClearIcon } from '../../assets/Thumbnail.svg';
import { Typography } from "@mui/material";

const AllClear = ({message}: any) => {

    return (
        <Box
            sx={{p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'stretch'}}
        >
            <AllClearIcon className={styles.clearIcon} />
            <Typography variant="titleMedium">
                All clear!
            </Typography>
            <Typography variant="bodyMedium">
                {message}
            </Typography>
        </Box>
    )

};

export default AllClear;