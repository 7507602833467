import paletteColors from '../palette';

const MuiCard = {
    styleOverrides: {
        root: {
            overflow: 'unset',
            borderRadius: '0.75rem',
            backgroundColor: `${paletteColors.surface.main}`,
            backgroundImage: 'none',
            padding: '1rem',
            boxShadow: '0px 2px 4px 0px rgba(146, 146, 146, 0.25) !important'
        },
    }
};

export default MuiCard;