// src/CustomPDF.js
import React, { useEffect, useState } from "react";
import { Link, Page, Document, StyleSheet } from "@react-pdf/renderer";
import Page1 from "./Common/Page1";
import DiseaseReports from "./screens/reports/DiseaseReports.js";
import ScoreReport from "./screens/reports/ScoreReport.js";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    flex: 1,
    position: "relative",
    fontFamily: "NotoSans",
  },
  PageCntnt: {},
  fixed: {
    marginBottom: 10,
  },
  middleContent: {
    marginBottom: 20,
  },
  fixed2: {
    width: "100%",
    paddingTop: "10px",
  },
  middleCntnt: {
    // marginTop: '70px'
  },
  header: {
    position: "absolute",
    top: 10,
    left: 30,
    right: 30,
    textAlign: "center",
    fontSize: 20,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 30,
    right: 30,
    textAlign: "center",
    fontSize: 12,
  },
  section: {
    marginBottom: 10,
    borderRadius: "2px",
    borderColor: "red",
    borderWidth: "4px",
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  listItem: {
    marginBottom: 2,
  },
});

function CustomPDF({
  diseaseReports,
  scores,
  error1,
  error2,
  firstPageScore,
  userName,
  id,
}) {
  return (
    <Document>
      <Page1
        firstPageRisk={diseaseReports}
        firstPageScore={firstPageScore}
        id={id}
        userName={userName}
      />
      <DiseaseReports
        diseaseReports={diseaseReports}
        error1={error1}
        userName={userName}
        id={id}
      />
      <ScoreReport
        scores={scores}
        error2={error2}
        userName={userName}
        id={id}
        
      />
    </Document>
  );
}

export default CustomPDF;
