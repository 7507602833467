import CustomAccordian from "../CustomAccordian";
import AllClear from "../../allClear";

function CustomDisease({
  risk = "",
  isDisplayAllSummary = true,
  dieseaseDetails = [],
}: any) {
  return (
    <>
      <CustomAccordian
        isDisplayAllSummary={isDisplayAllSummary}
        dieseaseDetails={dieseaseDetails}
      />
      {dieseaseDetails.length === 0 && (
        <AllClear message={`No ${risk} conditions detected.`} />
      )}
    </>
  );
}

export default CustomDisease;
