 const paletteColors = {
    primary: {
      main: '#00A0A8',
    },
    onPrimary: {
      main: '#FFFFFF',
    },
    primaryContainer: {
      main: '#00A0A8',
    },
    onPrimaryContainer: {
      main: '#FFFFFF',
    },
    
    secondary: {
      main: '#80D4DA',
    },
    onSecondary: {
      main: '#001D1F',
    },
    secondaryContainer: {
      main: '#C8F2F5',
    },
    onSecondaryContainer: {
      main: '#003538',
    },
    
    tertiary: {
      main: '#E4AD20',
    },
    onTertiary: {
      main: '#FFF',
    },
    tertiaryContainer: {
      main: '#FFD15B',
    },
    onTertiaryContainer: {
      main: '#3D2E01',
    },
    
    error: {
      main: '#B3261E',
    },
    onError: {
      main: '#FFFFFF',
    },
    errorContainer: {
      main: '#FFD4BC',
    },
    onErrorContainer: {
      main: '#410E0B',
    },
    
    primaryFixed: {
      main: '#FFDADB',
    },
    onPrimaryFixed: {
      main: '#3F020E', 
    },
    
    surface: {
      main: '#F8FEFF',
    },
    onSurface: {
      main: '#00A0A8',
    },
    surfaceVariant: {
      main: '#F4FAFB',
    },
    onSurfaceVariant: {
      main: '#001D1F',
    },
    outline: {
      main: '#00A0A8',
    },
    outlineVariant: {
      main: '#E3F0F0',
    },

    onBackground: {
      main: '#221A1A',
    },

    low: {
      main: '#34A853',
    },
    onLow: {
      main: '#FFFFFF',
    },
    lowContainer: {
      main: '#34A853',
    },
    onLowContainer: {
      main: '#FFFFFF',
    },
    moderate: {
      main: '#FBBC04',
    },
    onModerate: {
      main: '#FFF',
    },
    moderateContainer: {
      main: '#FBBC04',
    },
    onModerateContainer: {
      main: '#4D3800',
    },
    high: {
      main: '#EA4335',
    },
    onHigh: {
      main: '#FFF',
    },
    highContainer: {
      main: '#EA4335',
    },
    onLighContainer: {
      main: '#FFF',
    },
    
    
  };

  export default paletteColors;