
import paletteColors from '../palette';

const MuiTypography = {
    defaultProps: {
        variantMapping: {
            displayLarge: 'h1',
            displayMedium: 'h2',
            displaySmall: 'h3',
            headingLarge: 'h4',
            headingMedium: 'h5',
            headingSmall: 'h6',
            titleLarge: 'div',
            titleMedium: 'div',
            titleRegular:  'div',
            titleSmall: 'div',
            bodyLarge: 'div',
            bodyMedium: 'div',
            bodySmall: 'div',
            labelLarge: 'span',
            labelMedium: 'span',
            labelSmall: 'span',
        },
    },
    styleOverrides: {
        root: {
            color: `${paletteColors.onBackground.main}`,
        },
    }
};

export default MuiTypography;