import * as React from "react";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";

import Footer from "./Footer";
import Navbar from "../helpers/CustomNavbar";

const AppLayout = () => {

  return (
    <Box sx={{ display: "block" }}>
      <Box
        component="main"
        sx={{
          display: "flex",
          // flexGrow: 1,
          height: "100%",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        <Navbar />
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default AppLayout;
