import styles from './styles.module.css';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';

import { ReactComponent as RecommendationIcon } from '../../../assets/recommendation.svg';

const CustomRecommendation = ({recommendationList}: any) => {

    // const recommendationList = [
    //     "Eat a variety of iron-rich foods to ensure adequate intake, if possible opt for heme iron from animal sources for more efficient absorption.",
    //     "Combine iron-rich foods with vitamin C sources like citrus fruits for better non-heme (plant sources) iron absorption.",
    //     "Don't consume calcium-rich foods or supplements with iron-rich meals, as calcium can inhibit iron absorption.",
    //     "Thorough cooking and soaking of legumes & grains can reduce factors like phytates which inhibit iron absorption."
    // ];
    
    return (
        <Box>
            {
                recommendationList.map((recommendationItem: any, itemIndex: number) => (
                    <Box 
                        key={`recommendation-${itemIndex}`}
                        sx={{ border: 1, borderColor: 'outlineVariant.main', borderRadius: '12px', mb: '16px', padding: '16px', display: 'flex', gap: '12px', alignItems: 'flex-start' }}
                        className={styles.recommendationContainer}
                    >
                        <RecommendationIcon className={styles.recommendationIcon}/>

                        <Typography
                            variant='bodyMedium'
                            className={styles.recommendationTypo}
                        >
                            {recommendationItem}
                        </Typography>
                    </Box>
                ))
            }
        </Box>
    )

};

export default CustomRecommendation;