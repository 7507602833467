import React from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";

import Madmachine from "../assets/madmachine.png";
import Pulse from "../assets/pulse.png";

const styles = StyleSheet.create({
  header2: {
    flexDirection: "row",
    paddingHorizontal: 15,
    paddingVertical: 6,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f8feff",
  },
  content1Txt: {
    fontSize: "15px",
    fontFamily: "NotoSans",
  },
  content2: {
    flexDirection: "row",
    height: "40px",
  },
  content2Txt: {
    fontSize: "10px",
    paddingBottom: "5px",
    // paddingTop: '2px',
    fontFamily: "NotoSans",
  },
  pulse: {
    height: "35px",
    width: "35px",
    marginRight: 8,
  },
  madmachine: {
    height: "30px",
    width: "50px",
  },
  content3: {
    alignItems: "flex-end",
  },
  content3Txt: {
    fontSize: "11px",
    fontFamily: "NotoSans",
  },
});

function Header2({ userName }) {
  return (
    <View style={styles.header2}>
      <Text style={styles.content1Txt}>Health Prognosis Report</Text>
      <View style={styles.content2}>
        <Image src={Madmachine} style={styles.madmachine} />
      </View>
      {/* <View style={styles.content3}>
        <Text style={styles.content3Txt}>
          {userName.length > 0 ? userName : "Patron"}
        </Text>
      </View> */}
    </View>
  );
}

export default Header2;
