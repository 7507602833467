import styles from './styles.module.css';
import Box from '@mui/material/Box';
import { ReactComponent as FooterImage } from '../../assets/Footer.svg';

const Footer = () => {

    return (
        <Box
            component="footer"
            className={styles.footerContainer}
        >
            <FooterImage />
        </Box>
    )

};

export default Footer;