import paletteColors from '../palette';

const MuiDialogActions = {
    styleOverrides: {
        root: {
            padding: '1.5rem',
            borderTop: `1px solid ${paletteColors.outlineVariant.main}`
        },
    }
};

export default MuiDialogActions;