import {useEffect} from 'react';
import axios from 'axios'
import {useApp} from './check-app';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  headers: {'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420"},
  timeout: 50000,
});

const makeAPIRequest = (options: any) => {
  return apiClient(options)
}

const AxiosInterceptor = ({children}: any) => {

  const {setSessionExpired, onShowSnackbar}: any = useApp();
  useEffect(() => {

    const resInterceptor = (response: any) => {
      return response;
    }

    const errInterceptor = (error: any) => {
      if (error.response.status === 401) {
        setSessionExpired(true, error?.response?.data?.error || "Please look into apiClient.interceptors.response")
      } else {
      }
      if (error.response.status === 500) {
        onShowSnackbar(error?.response?.data?.error, "error")
      } else {
      }
      return Promise.reject(error);
    }


    const interceptor = apiClient.interceptors.response.use(resInterceptor, errInterceptor);

    return () => apiClient.interceptors.response.eject(interceptor);

  }, [children]);

  return children;
}

export {makeAPIRequest, AxiosInterceptor}
