import React from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import AllClear from "../assets/All_clear.png";

const tableHeader = ["Priority Action", "Timely Action", "Routine Action"];
const tableContentElements = ["High Risk", "Moderate Risk", "Low Risk"];
const styles = StyleSheet.create({
  riskTbale: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  riskTableData: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginHorizontal: "10px",
    paddingRight: "10px",
    marginTop: 14,
    width: "100%",
  },
  riskTbaleElements: {
    flexDirection: "column",
    alignItems: "flex-start",
    width: "160px",
  },
  tableHeaderSection: {
    paddingBottom: "5px",
    borderBottom: "2px",
    borderColor: "#e3f0f0",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "#f8feff",
  },
  tableHeader: {
    paddingTop: "10px",
    color: "#00A0A8",
    fontWeight: "medium",
    fontFamily: "NotoSans",
    fontSize: "12px",
  },
  riskElemnt: {
    flexDirection: "row",
    borderBottom: "1px",
    borderColor: "#e3f0f0",
    paddingVertical: "14px",
    paddingHorizontal: "10px",
    width: "100%",
  },
  riskIcon: {
    height: "25px",
    width: "25px",
    marginRight: "14px",
  },
  riskName: {
    fontSize: "11px",
    fontFamily: "NotoSans",
    flex: 1,
    whiteSpace: "pre-wrap",
  },
});

function RiskTable({ firstPageRisk }) {
  return (
    <View style={styles.riskTbale}>
      <View style={styles.tableHeaderSection}>
        {tableHeader.map((e) => {
          return <Text style={styles.tableHeader}>{e}</Text>;
        })}
      </View>
      <View style={styles.riskTableData}>
        {/* {Object.keys(firstPageRisk).map((riskLevel, riskLevelIndex) => (
          <View style={styles.riskTbaleElements}>
            {firstPageRisk[riskLevel].map((risk) => {
              return (
                <View style={styles.riskElemnt}>
                  <Image
                    cache={false}
                    src={{
                      uri: risk["image_url"],
                      method: "GET",
                      headers: {
                        "Cache-Control": "no-cache",
                        "Access-Control-Allow-Origin": "*",
                      },
                      body: "",
                    }}
                    style={styles.riskIcon}
                  />

                  <Text style={styles.riskName}>{risk["risk_name"]}</Text>
                </View>
              );
            })}
          </View>
        ))} */}
        {tableContentElements.map((e) => {
          if (firstPageRisk[e]) {
            return (
              <View style={styles.riskTbaleElements}>
                {firstPageRisk[e].map((risk) => {
                  return (
                    <View style={styles.riskElemnt}>
                      <Image
                        cache={false}
                        src={{
                          uri: risk["image_url"],
                          method: "GET",
                          headers: {
                            "Cache-Control": "no-cache",
                            "Access-Control-Allow-Origin": "*",
                          },
                          body: "",
                        }}
                        style={styles.riskIcon}
                      />

                      <Text style={styles.riskName}>{risk["risk_name"]}</Text>
                    </View>
                  );
                })}
              </View>
            );
          } else {
            return (
              <View style={styles.riskTbaleElements}>
                <View style={styles.riskElemnt}>
                  <Image
                    cache={false}
                    src={{
                      uri: AllClear,
                      method: "GET",
                      headers: {
                        "Cache-Control": "no-cache",
                        "Access-Control-Allow-Origin": "*",
                      },
                      body: "",
                    }}
                    style={styles.riskIcon}
                  />

                  <Text style={styles.riskName}>{`No ${e}`}</Text>
                </View>
              </View>
            );
          }
        })}
      </View>
    </View>
  );
}

export default RiskTable;
