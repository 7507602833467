import paletteColors from "../palette";

const MuiAccordion = {
  styleOverrides: {
    root: {
      borderRadius: "4px !important",
      border: `1px solid ${paletteColors.surface.main}`,
      boxShadow: "0px 4px 0px -3px #E3F0F0;",
      borderBottom: `1px solid #e3f0f0`,
      marginBottom: "0.75rem !important",
      "&::before": {
        content: "none", 
      },
    },
  },
};

export default MuiAccordion;
