import React from "react";

import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
const DisclaimerList = [
  "Our models have been trained on vast datasets covering blood biomarkers, demography & other publicly available data to identify complex relationships between health indicators and disease outcomes in the Indian population.",
  "The intelligence provided by the models is based on data from the general population and may not capture the intricacies and nuances of extreme or rare medical cases.",
  "Current predictions are based exclusively on the parameters provided by the client.",
  "The models used in this report have inherent limitations and may not perfectly capture all complexities of disease risk. ",
  "This report is intended for informational purposes only and should not substitute for professional medical advice or diagnosis.",
  "Consult a healthcare professional for personalized assessment and treatment.",
];
const PopupModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="center" alignItems="center">
          <InfoIcon />
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* <Typography variant='body1'  >
                    How is the health risk calculated?
                </Typography>
                <Typography paragraph={true} variant='bodySmall'  >
                    We have used machine learning algorithms to analyse vast datasets and identify complex relationship between risk factors and disease outcomes in Indian population.This helps us customize predictions about your disease risks using your individual health information.
                </Typography> */}
        <Typography variant="body1">Disclaimer</Typography>
        {DisclaimerList.map((e) => (
          <Typography style={{ marginTop: "5px" }} variant="bodySmall">
            {" "}
            • {e}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupModal;
