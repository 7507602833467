import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  diseaseAbout: {
    borderRadius: "5px",
    borderWidth: "1.5px",
    borderColor: "#E3F0F0",
    flexDirection: "column",
    padding: "10px",
  },
  recommendationsTxt: {
    fontSize: "11px",
    fontWeight: "medium",
    fontFamily: "NotoSans",
  },
  recommendationsArea: {
    paddingVertical: "10px",
    paddinH: "5px",
    width: "100%",
    paddingHorizontal: "7px",
  },
  listItem: {
    flexDirection: "row",
    paddingTop: 5,
    alignItems: "flex-start",
    paddingVertical: "5px",
  },
  bulletPoint: {
    width: 5,
    fontSize: 10,
    marginRight: 2,
  },
  recommendationsPts: {
    fontSize: "11px",
    fontFamily: "NotoSans",
  },
});

function Recommendation({ recommendations, ref }) {
  return (
    <View style={styles.diseaseAbout}>
      <Text style={styles.recommendationsTxt}>Recommendations</Text>
      <View style={styles.recommendationsArea}>
        {recommendations.map((e) => (
          <View style={styles.listItem}>
            <Text style={styles.bulletPoint}>•</Text>
            <Text style={styles.recommendationsPts}>{e}</Text>
          </View>
        ))}
      </View>
    </View>
  );
}

export default Recommendation;
