export const DEFAULT_SUMMARY_LIST_DISPLAY_COUNT = 1;

export const API_ENDPOINT = {
    GET_ALL_DISEASE_PREDICTIONS: "https://madmachines.datasyndicate.in/v1/api/disease_predictions",
    GET_ALL_SCORES_PREDICTIONS: "https://madmachines.datasyndicate.in/v1/api/score_recommendations",
    SAVE_SCORE: "https://madmachines.datasyndicate.in/v1/api/save_score_question_response",
    SAVE_USER_DETAILS: "https://madmachines.datasyndicate.in/v1/api/save_user_details",
    GET_USER_DETAILS: "https://madmachines.datasyndicate.in/v1/api/get_user_details",
    GET_ANSWER_COUNTS_PERCENTAGE: "https://madmachines-api-32xupa3pnq-el.a.run.app/v1/api/answered_counts_percentage",
    GET_SCORE_LABELS:"https://madmachines.datasyndicate.in/v1/api/score_labels",
    SAVE_REPORTS:"https://madmachines-api-32xupa3pnq-el.a.run.app/v1/api/save_reports",
}