import paletteColors from '../palette';

const MuiDialog = {
    styleOverrides: {
        paper: {
            borderRadius: '1.75rem',
            padding: '0px',
            backgroundColor: paletteColors.surface.main,
            boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.25)`
        },
        paperFullScreen: {
            borderRadius: '0',
            backgroundColor: '#fff',
        }
    }
};

export default MuiDialog;