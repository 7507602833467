import React from "react";
import { StyleSheet, View } from "@react-pdf/renderer";
import Header1 from "./Header1";
import Header2 from "./Header2";

const styles = StyleSheet.create({
  header: {
    flexDirection: "column",
  },
});

function Header({ userName }) {
  return (
    <View style={styles.header}>
      <Header1 />
      <Header2 userName={userName} />
    </View>
  );
}

export default Header;
