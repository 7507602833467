import paletteColors from '../palette';

const MuiButton = {
    styleOverrides: {
        root: {
            borderRadius: '6.25rem',
            "&:hover": {
                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
            }
        }
    },
};

export default MuiButton;