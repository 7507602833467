import { Font, PDFViewer, pdf } from "@react-pdf/renderer";
import NotoSans from "../../assets/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "../../assets/fonts/NotoSans-Bold.ttf";
import NotoSansMedium from "../../assets/fonts/NotoSans-Medium.ttf";
import NotoSansSemiBold from "../../assets/fonts/NotoSans-Medium.ttf";
import CustomPDF from "../../CustomPDF";
import { useEffect, useRef, useState } from "react";
import FileSaver from "file-saver";
import axios from "axios";
import { useApp } from "../../../hook/check-app";
import { diseaseAPI } from "../../../apis";

Font.register({
  family: "NotoSans",
  fonts: [
    { src: NotoSans },
    { src: NotoSansBold, fontWeight: "bold" },
    { src: NotoSansMedium, fontWeight: "medium" },
    { src: NotoSansSemiBold, fontWeight: "semiBold" },
  ],
  format: "truetype",
});

const healthScores = [
  "Physical Activity Score",
  "Sleep Score",
  "Stress Score",
  "Fatty Liver Score",
  "Metabolic Score",
];

const getRoundedValue = (value, denominator, isSubtract) => {
  let scoreValue = value;
  let calculatedValue = (scoreValue / denominator) * 100;
  let roundedValue = Math.round(calculatedValue * 10) / 10;
  let finalValue = 100 - roundedValue;
  finalValue = Math.round(finalValue * 100) / 100;
  return isSubtract ? finalValue : roundedValue;
};

const Entry = () => {
  const {
    email: id,
    canDownloadPdf,
    canUploadPdfonCloud,
    setCanUploadPdfonCloud,
    setCanDownlaodPdf,
  } = useApp();
  const [diseaseReports, setdiseaseReports] = useState(null);
  const [scores, setscores] = useState(null);
  const [firstPageScore, setFirstPageScore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error1, setError1] = useState(null);
  const [error2, setError2] = useState(null);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const initialLoadDiseae = useRef(true);

  const removeDuplicatesAndEmpty = (arr) => {
    const uniqueItems = Array.from(
      new Set(arr.filter((item) => item.trim() !== ""))
    );
    return uniqueItems.map((item) => item + " ");
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    if (id && id.length > 0 && userId.length === 0) {
      setUserId(id);
    }
  }, [id]);

  useEffect(() => {
    if (initialLoadDiseae.current) {
      initialLoadDiseae.current = false;

      const fetchDataDisease = async () => {
        try {
          const response1 = await fetch(
            `https://madmachines.datasyndicate.in/v1/api/disease_predictions?user_id=${id}`
          );
          const diseaseReports = await response1.json();
          if (
            diseaseReports &&
            diseaseReports.name &&
            diseaseReports.name.length > 0
          ) {
            setUserName(capitalizeWords(diseaseReports.name));
          }

          if (diseaseReports && diseaseReports.risks) {
            const processedRisks = Object.keys(diseaseReports.risks).reduce(
              (acc, riskLevel) => {
                acc[riskLevel] = diseaseReports.risks[riskLevel].map((risk) => {
                  const updatedRisk = { ...risk };
                  if (risk.about && risk.about.length > 0) {
                    updatedRisk.about = removeDuplicatesAndEmpty(risk.about);
                  }
                  if (risk.recommendations && risk.recommendations.length > 0) {
                    updatedRisk.recommendations = risk.recommendations.map(
                      (recommendation) =>
                        removeDuplicatesAndEmpty(recommendation)
                    );
                  }
                  if (risk["Risk Factors"] && risk["Risk Factors"].length > 0) {
                    updatedRisk["Risk Factors"] = removeDuplicatesAndEmpty(
                      risk["Risk Factors"]
                    );
                  }
                  return updatedRisk;
                });
                return acc;
              },
              {}
            );

            setdiseaseReports({ risks: processedRisks });
          }
        } catch (error) {
          setError1(error.message);
          console.error("Error fetching data:", error);
        }
      };

      const fetchDataScore = async () => {
        try {
          const response = await axios.get(
            `https://madmachines.datasyndicate.in/v1/api/score_recommendations?user_id=${id}`
          );
          const data = response.data;
          if (!data || Object.keys(data).length === 0) {
            throw new Error("Your Health Score isn't ready yet");
          }

          if (
            data.recommendations &&
            data.recommendations.name &&
            data.recommendations.name.length > 0
          ) {
            setUserName(capitalizeWords(data.recommendations.name));
          }

          const extracted = Object.keys(data.recommendations).reduce(
            (result, scoreName) => {
              if (scoreName !== "name") {
                const item = data.recommendations[scoreName];
                if (
                  item &&
                  Object.keys(item).length > 0 &&
                  Object.keys(item.recommendations).length > 0
                ) {
                  let updatedScore;
                  if (item.score != null) {
                    switch (scoreName) {
                      case "Physical Activity Score":
                        updatedScore = getRoundedValue(item.score, 45, true);
                        break;
                      case "Metabolic Score":
                        updatedScore = getRoundedValue(item.score, 100, true);
                        break;
                      case "Stress Score":
                        updatedScore = getRoundedValue(item.score, 40, false);
                        break;
                      case "Sleep Score":
                        updatedScore = getRoundedValue(item.score, 18, false);
                        break;
                      case "Fatty Liver Score":
                        updatedScore = getRoundedValue(item.score, 100, false);
                        break;
                    }
                  }
                  result.push({
                    name: scoreName,
                    score: item.score !== null ? updatedScore : null,
                    max_score: item.max_score !== null ? item.max_score : null,
                    label: item.label !== null ? item.label : null,
                    scoreLabel:
                      item.score_label !== null ? item.score_label : null,
                  });
                }
              }
              return result;
            },
            []
          );

          if (extracted.length === 0) {
            setError2("No score found.");
            setscores([]); // Clear any existing scores
          } else {
            const scoresWithRecommendations = Object.keys(
              data.recommendations
            ).reduce((acc, scoreName) => {
              if (scoreName !== "name") {
                const item = data.recommendations[scoreName];
                if (item && Object.keys(item.recommendations).length > 0) {
                  let updatedScore;
                  if (item.score !== null) {
                    switch (scoreName) {
                      case "Physical Activity Score":
                        updatedScore = getRoundedValue(item.score, 45, true);
                        break;
                      case "Metabolic Score":
                        updatedScore = getRoundedValue(item.score, 100, true);
                        break;
                      case "Stress Score":
                        updatedScore = getRoundedValue(item.score, 40, false);
                        break;
                      case "Sleep Score":
                        updatedScore = getRoundedValue(item.score, 18, false);
                        break;
                      case "Fatty Liver Score":
                        updatedScore = getRoundedValue(item.score, 100, false);
                        break;
                    }
                  }
                  acc[scoreName] = { ...item, score: updatedScore };
                }
              }
              return acc;
            }, {});

            let firstPageScores = [...extracted]; // Start with the existing scores
            const defaultContent = "Not Filled";
            const keysInA2 = new Set(extracted.map((item) => item.name));

            healthScores.forEach((key) => {
              if (!keysInA2.has(key)) {
                firstPageScores.push({ name: key, content: defaultContent });
              }
            });

            setscores({ recommendations: scoresWithRecommendations });
            setError2(null);
            setFirstPageScore(firstPageScores);
          }
        } catch (error) {
          setError2(
            "Your Health Score isn't ready yet. Please fill all the Questions"
          );
          console.error("Error fetching data:", error);
        }
      };

      const callPdf = async () => {
        setLoading(true);
        await fetchDataDisease();
        await fetchDataScore();
        setLoading(false);
      };

      if (canDownloadPdf) callPdf();
    }
  }, [canDownloadPdf]);

  useEffect(() => {
    if (!loading && canDownloadPdf) {
      downloadPDF();
      setCanDownlaodPdf(false);
    }
  }, [loading, canDownloadPdf]);

  const downloadPDF = async () => {
    try {
      // Generate the PDF blob

      const blob = await pdf(
        <CustomPDF
          diseaseReports={diseaseReports}
          scores={scores}
          error1={error1}
          error2={error2}
          firstPageScore={firstPageScore}
          userName={userName}
          id={userId}
        />
      ).toBlob();

      // Save the PDF locally if needed
      if (!(canDownloadPdf && canUploadPdfonCloud))
        FileSaver.saveAs(blob, "report.pdf");

      if (canUploadPdfonCloud) {
        console.log("Starting upload on cloud");

        // Convert the blob to a base64 string (keep the full data URL, including MIME type)
        const base64Blob = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); // Get the full base64 data URL
          reader.onloadend = () => resolve(reader.result); // Keep the entire data URL
          reader.onerror = reject;
        });

        // Strip the `data:application/pdf;base64,` prefix from the base64 string
        const base64Content = base64Blob.split(",")[1]; // Only take the base64 part

        console.log("base64Blob:", base64Blob);
        console.log("base64Content:", base64Content);

        // Send the base64 string to the API
        const payload = {
          client: "madmachines",
          user_id: id, // Changed to match the passed userId prop
          pdf_blob: base64Content, // Use the base64 content without the prefix
        };
        const response = await diseaseAPI.savePdf(payload);
        console.log("response:", response);
        console.log("PDF uploaded successfully");
        setCanUploadPdfonCloud(false);
      }
    } catch (error) {
      console.error("Error generating or uploading PDF:", error);
      throw new Error(error.message);
    }
  };

  return <></>;
};

export default Entry;
