
const MuiAccordionDetails = {
    styleOverrides: {
        root: {
            padding: '0px !important',
            borderTop: 'none',
        }
    }
};

export default MuiAccordionDetails;