import React, { useEffect } from "react";
import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import ReportPrint from "../../ReportPrint/ReportPrint";

const styles = StyleSheet.create({
  body: { paddingTop: 140, paddingBottom: 150, fontFamily: "NotoSans" },
  section: {
    marginBottom: 10,
    borderRadius: "2px",
    borderColor: "red",
    borderWidth: "4px",
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  listItem: {
    marginBottom: 2,
  },
  header: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 20,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 12,
  },
});

function ScoreReport({ scores, userName, error2, id }) {
  return (
    <>
      {error2 && (
        <Page style={styles.body}>
          <View wrap={false} style={styles.header} fixed>
            <Header userName={userName} />
          </View>
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Text style={{ fontFamily: "NotoSans", fontSize: "15px" }}>
              {error2}
            </Text>
          </View>

          <View wrap={false} style={styles.footer} fixed>
            <Footer height={80} id={id} />
          </View>
        </Page>
      )}

      {!error2 && scores && (
        <Page style={styles.body}>
          <View wrap={false} style={styles.header} fixed>
            <Header userName={userName} />
          </View>
          {Object.keys(scores.recommendations).map(
            (scoreName, scoreNameIndex) => {
              return (
                <ReportPrint
                  ishealthScore={true}
                  name={scoreName}
                  score={scores.recommendations[scoreName]["score"]}
                  maxScore={scores.recommendations[scoreName]["max_score"]}
                  label={scores.recommendations[scoreName]["label"]}
                  scoreLabel={scores.recommendations[scoreName]["score_label"]}
                  image={scores.recommendations[scoreName]["image_url"]}
                  about={
                    scores.recommendations[scoreName]["recommendations"][
                      "About the Score & Risk"
                    ]
                      ? scores.recommendations[scoreName]["recommendations"][
                          "About the Score & Risk"
                        ]
                      : []
                  }
                  riskFactor={
                    scores.recommendations[scoreName]["recommendations"][
                      "Risk Factors"
                    ]
                      ? scores.recommendations[scoreName]["recommendations"][
                          "Risk Factors"
                        ]
                      : []
                  }
                  recommendations={
                    scores.recommendations[scoreName]["recommendations"][
                      "Mitigation_Strategies_Heading"
                    ]
                      ? scores.recommendations[scoreName]["recommendations"][
                          "Mitigation_Strategies_Heading"
                        ]
                      : []
                  }
                  error={error2}
                />
              );
            }
          )}

          <View wrap={false} style={styles.footer} fixed>
            <Footer height={80} id={id} />
          </View>
        </Page>
      )}
    </>
  );
}

export default ScoreReport;
