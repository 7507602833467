import paletteColors from '../palette';

const MuiAccordionSummary = {
    styleOverrides: {
        root: {
            backgroundColor: `${paletteColors.surface.main} !important`,
            // flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg)',
            },
            '& .MuiAccordionSummary-content': {
                marginLeft: '0.5rem !important',
            },
            height: '72px'
        },
        content: {
            alignItems: 'center !important',
            gap: '1rem !important'
        }
    }
};

export default MuiAccordionSummary;