import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import { Popover, Grid, Box } from "@mui/material";
import PopupModal from "../../app-layout/PopupModal";
import { useApp } from "../../../hook/check-app";
import styles from "./styles.module.css";
import Header from "../../../assets/Header.png";
import DXAI from "../../../assets/DXAI.png";

export interface AnsweredPercentage {
  answered: number;
  percentage: number;
  total: number;
}

const Navbar = () => {
  const { appLoading, initialPage, appTitle } = useApp();
  const { email } = useParams();
  const [message, setMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const { getAnswerPercentage, userName } = useApp();

  const openDialog =
    email?.includes("&") &&
    email?.split("&").length > 1 &&
    email?.split("&")[1].split("=")[1];

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    if (openDialog) {
      setOpenPopup(true);
    }
  }, [openDialog]);

  const handleClose = () => {
    setAnchorEl(null);
    setMessage("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let subHeading = "";

  if (initialPage > 3 && email) {
    if (appTitle.includes("Score")) {
      subHeading =
        "Explore this section for insights and tips on improving your health score.";
    } else if (appTitle === "Health Risk") {
      subHeading =
        "Based on your blood reports, we've categorized your health risk into three levels: Requires Priority Action, Needs Timely Action and Needs Routine Action.";
    } else {
      subHeading = `Hi ${
        userName.length > 0 ? userName : "Patron"
      } , unlock your personalized health insights with your advance blood analysis report! `;
    }
  }

  return (
    <AppBar position="static" color="primary">
      <Toolbar disableGutters className={styles.headerToolbar}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <img
            style={{ height: "45px", width: "140px" }}
            src={Header}
            alt="header-image"
          />

          <img
            style={{
              height: "35px",
              width: "70px",
              position: "absolute",
              right: 0,
              top: 15,
              marginRight:'16px'
            }}
            src={DXAI}
            alt="DXAI-image"
          />
        </Box>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          mt={4}
        >
          <Grid
            item
            xs={8}
            mb={1.5}
            container
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography
              variant="titleMedium"
              color="onPrimary.main"
              style={{
                display: "flex",
                height: "48px !important",
              }}
            >
              {appLoading ? "" : appTitle}
            </Typography>
          </Grid>
          {initialPage > 3 && (
            <Grid item xs={4} mb={1.5} container justifyContent="flex-end">
              {!appLoading && (
                <>
                  <IconButton
                    id="info-icon-button"
                    style={{ color: "#fff" }}
                    onClick={(event) => setOpenPopup(true)}
                  >
                    <InfoIcon />
                  </IconButton>
                  <IconButton
                    id="file-download-icon-button"
                    style={{ color: "#fff" }}
                    // onClick={(event) => handleFileDownloadClick(event)}
                    onClick={() => getAnswerPercentage(true, true)}
                  >
                    <FileDownloadIcon />
                  </IconButton>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Toolbar>
      {initialPage > 3 && (
        <Toolbar disableGutters>
          <Typography
            variant="body2"
            color="inherit"
            className={styles.headerDescription}
          >
            {appLoading ? "" : subHeading}
          </Typography>
        </Toolbar>
      )}

      {message && message.length > 0 && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ p: 2 }}>{message}</Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </div>
        </Popover>
      )}
      <PopupModal open={openPopup} onClose={handleClosePopup} />
    </AppBar>
  );
};

export default Navbar;
